<template>
  <tr>
    <td>&nbsp;&nbsp;&nbsp;&nbsp; {{ division }}</td>
    <td></td>
    <td></td>
    <td>€ {{new Intl.NumberFormat('en-US').format(Math.round(details.total_spent))}}</td>
    <td></td>
    <td>{{Number(details.median_compa_ratio).toFixed(2)}}</td>
    <td>{{Number(details.new_median_compa_ratio).toFixed(2)}}</td>
  </tr>
</template>

<script>
export default {
  name: 'DivisionDetail',
  props: {
    division: {
      type: String,
      required: true
    },
    details: Object
  }
}
</script>