<template>
  <div class="mt-2" style="font-family: 'Plus Jakarta Sans'">
    <div class="w-5/6 justify-center mx-auto m-2" v-if="cycle && departmentalDetails">
      <p class="text-3xl mb-3 text-center font-bold">{{cycle.name}}</p>

      <div class="mt-2 grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-1 gap-4">
        <div class="backdrop-blur-sm p-6 rounded-xl hover:border-1 transition-colors duration-300 overflow-hidden border border-color w-full overflow-x-auto">
          <h2 class="font-semibold text-xl tracking-tight text-color mb-4">
            Budget
            <span class="font-normal text-sm">
              <a :href="overviewLink" class="hover:underline text-blaze-orange-600">Switch to cycle overview</a>
            </span>
          </h2>

          <table class="w-full table table-auto">
            <tr>
              <th class="text-left">Department</th>
              <th class="text-left">Original Budget</th>
              <th class="text-left">% to Payroll</th>
              <th class="text-left">Total Spent</th>
              <th class="text-left">Remaining Budget</th>
              <th class="text-left">Compa Ratio Before</th>
              <th class="text-left">Compa Ratio After</th>
              <th class="text-left">Comments</th>
            </tr>

            <template v-for="department in departments">
              <department-detail
                  :department="department"
                  :details="departmentalDetails[department]"
                  :cycle="cycle"
                  :mentionables="mentionables"
                  :division-details="divisionDetails[department]"
                  @show-modal="toggleModal(department)"
              />
            </template>

            <department-detail department="Totals" :details="totals" :cycle="cycle" :mentionables="mentionables" :division-details="[]" />
          </table>
        </div>
      </div>

      <div class="mt-4 p-1" v-if="cycle.pay_equality_dashboard_url">
        For a detailed report of Camundi increases and the final pay equality analysis, please see the following <a :href="cycle.pay_equality_dashboard_url" class="hover:underline text-blaze-orange-600" target="_blank">workbook</a>. In the next iteration of CamundiPay, we plan to incorporate these metrics directly in this dashboard.
      </div>


      <div class="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-1 gap-4 mt-5">
        <div class="backdrop-blur-sm p-6 rounded-xl hover:border-1 transition-colors duration-300 overflow-hidden border border-color w-full overflow-x-auto">

          <table class="w-full table  table-auto">
            <tr>
              <th class="text-left">Approver</th>
              <th class="text-left">Status</th>
              <th class="text-left">Approved At</th>
              <th class="text-left">Action</th>
            </tr>

            <template v-for="approver in approvals">
              <approval-detail :approver="approver" :user="user" :cycle-id="cycle.id" @update-source="getSourceData()" />
            </template>
          </table>
        </div>
      </div>
    </div>
  </div>

  <div v-if="showModal && currentDepartment">
    <CommitteeComments
        :comments="departmentalDetails[currentDepartment].comments"
        :department="currentDepartment"
        :cycle-id="cycle.id"
        :mentionables="mentionables"
        @close="closeModal"
    />
  </div>

</template>

<script>

import CommitteeComments from './CommitteeComments.vue'
import DepartmentDetail from "./committee/DepartmentDetail.vue";
import ApprovalDetail from "./committee/ApprovalDetail.vue";
export default {
  name: 'CompensationCommittee',
  components: {
    ApprovalDetail,
    CommitteeComments,
    DepartmentDetail
  },
  props: {
    cycleId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      cycle: null,
      sourceData: null,
      departmentalDetails: null,
      divisionDetails: null,
      departments: [],
      approvals: [],
      user: null,
      comments: null,
      showModal: false,
      currentDepartment: false,
      mentionables: [],
      totals: []
    }
  },
  mounted() {
    this.getSourceData()
  },
  computed: {
    overviewLink() {
      return `/compensation_cycles/${this.cycle.id}/comp_committee_full_show`
    }
  },
  methods: {
    async getSourceData() {
      await fetch('/compensation_cycles/comp_committee_data.json?id=' + this.cycleId, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
        },
        credentials: 'same-origin'
      })
          .then(response => response.json())
          .then(data => {
            this.setData(data)
          })
    },
    setData(data) {
      this.sourceData = data
      this.cycle = data.compensation_cycle
      this.departments = data.departments
      this.departmentalDetails = data.departmental_details
      this.divisionDetails = data.divisional_details
      this.approvals = data.approvals
      this.user = data.user
      this.mentionables = data.mentionables
      this.totals = data.totals
    },
    toggleModal(department) {
      this.currentDepartment = department
      this.showModal = true
    },
    closeModal() {
      this.getSourceData()
      this.currentDepartment = null
      this.showModal = false
    }
  }
}
</script>
