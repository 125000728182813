<template>
  <tr @click="toggleDivisionDetails()">
    <td class="cursor-pointer">{{department}}</td>
    <td>€ {{ new Intl.NumberFormat('en-US').format(Math.round(details.amount)) }}</td>
    <td>{{Number(details.amount / details.payroll_amount * 100).toFixed(2)}}%</td>
    <td>€ {{new Intl.NumberFormat('en-US').format(Math.round(details.total_spent))}}</td>
    <td>€ {{new Intl.NumberFormat('en-US').format(Math.round(Number(details.amount) - Number(details.total_spent)))}}</td>
    <td>{{Number(details.median_compa_ratio).toFixed(2)}}</td>
    <td>{{Number(details.new_median_compa_ratio).toFixed(2)}}</td>
    <td>
      <div class="text-gray-900 whitespace-no-wrap ml-3 text-left">
        <span @click="emitModal()" class="inline-flex items-baseline cursor-pointer" v-if="details.comments && details.comments.length > 0">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 self-center w-5 h-5 rounded-full mx-1">
            <path stroke-linecap="round" stroke-linejoin="round" d="M8.625 9.75a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Zm0 0H8.25m4.125 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Zm0 0H12m4.125 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Zm0 0h-.375m-13.5 3.01c0 1.6 1.123 2.994 2.707 3.227 1.087.16 2.185.283 3.293.369V21l4.184-4.183a1.14 1.14 0 0 1 .778-.332 48.294 48.294 0 0 0 5.83-.498c1.585-.233 2.708-1.626 2.708-3.228V6.741c0-1.602-1.123-2.995-2.707-3.228A48.394 48.394 0 0 0 12 3c-2.392 0-4.744.175-7.043.513C3.373 3.746 2.25 5.14 2.25 6.741v6.018Z" />
          </svg>
        </span>

        <span @click="emitModal()" class="inline-flex items-baseline cursor-pointer self-center w-5 h-5 rounded-full mx-1" v-else>
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
            <path stroke-linecap="round" stroke-linejoin="round" d="M2.25 12.76c0 1.6 1.123 2.994 2.707 3.227 1.087.16 2.185.283 3.293.369V21l4.076-4.076a1.526 1.526 0 0 1 1.037-.443 48.282 48.282 0 0 0 5.68-.494c1.584-.233 2.707-1.626 2.707-3.228V6.741c0-1.602-1.123-2.995-2.707-3.228A48.394 48.394 0 0 0 12 3c-2.392 0-4.744.175-7.043.513C3.373 3.746 2.25 5.14 2.25 6.741v6.018Z" />
          </svg>
        </span>
      </div>
    </td>
  </tr>

  <template v-for="division in divisions" v-if="showDivisionDetails">
    <division-detail :division="division" :details="divisionDetails[division]"></division-detail>
  </template>


</template>

<script>


import DivisionDetail from "./DivisionDetail.vue";

export default {
  name: 'DepartmentDetail',
  components: {DivisionDetail},
  props: {
    department: {
      type: String,
      required: true
    },
    details: Object,
    cycle: Object,
    divisionDetails: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      showDivisionDetails: false
    }
  },
  computed: {
    divisions() {
      return Object.keys(this.divisionDetails)
    }
  },
  methods: {
    emitModal() {
      this.$emit('show-modal', this.department)
    },
    toggleDivisionDetails() {
      this.showDivisionDetails = !this.showDivisionDetails
    }
  }
}
</script>