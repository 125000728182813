<template>
  <tr>
    <td>{{approver.name}}</td>
    <td>{{formattedStatus}}</td>
    <td>{{approver.approval.approved_at}}</td>
    <td>
      <div v-if="approvalForUser && approver.approval.status == 'requested'">
        <button class="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded" @click="approve()">
          Approve Compensation Cycle
        </button>
      </div>
      <div v-else>
        N/A
      </div>
    </td>
  </tr>
</template>

<script>
export default {
  name: 'ApprovalDetail',
  props: {
    approver: Object,
    user: Object,
    cycleId: String
  },
  computed: {
    approvalForUser() {
      return this.approver.approval.user_id == this.user.id
    },
    formattedStatus() {
      return this.approver.approval.status
          .replace(/_/g, ' ')
          .split(' ')
          .map(word => word.charAt(0).toUpperCase() + word.slice(1))
          .join(' ');
    }
  },
  methods: {
    approve() {
      fetch(`/compensation_cycles/${this.cycleId}/cycle_committee_approvals/${this.approver.approval.id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
        },
        credentials: 'same-origin',
        body: JSON.stringify({
          cycle_committee_approval: {status: 'approved', approved_at: new Date()}
        })
      }).then(response => response.json())
          .then(data => {
            this.$emit('update-source')
          })
    },
  }
}
</script>