<template>
  <transition name="modal-fade w-full justify-center mx-auto m-2 overflow-auto">
    <div class="modal-backdrop w-full justify-center mx-auto m-2 overflow-auto">
      <div ref="modal" class="modal w-3/4 justify-center m-2 overflow-auto"
           tabindex="0"
           role="dialog"
           aria-labelledby="modalTitle"
           aria-describedby="modalDescription"
           @keydown.esc="close"
      >
        <header class="modal-header"  id="modalTitle">
          <p class="text-blaze-orange-600">Send back {{planner.name}}'s worksheet</p>
        </header>

        <section class="modal-body mx-4" id="modalDescription">
          <div class="bg-teal-100 border border-teal-400 text-teal-700 px-4 py-3 rounded relative" role="alert">
            <strong class="font-bold">You will send back the worksheet with the following values:</strong>
            <ul>
              <li v-for="participant in participants" class="list-disc ml-8">
                <p v-if="participant.total_percentage_increase > 0.0">
                  {{participant.name}} receives a total percentage increase of {{participant.total_percentage_increase}}% resulting in a new compa ratio of {{Number(participant.new_compa_ratio).toFixed(2)}}
                </p>
                <p v-else>
                  {{participant.name}} receives no increase
                </p>
              </li>
            </ul>
          </div>

          <div class="mt-4" v-if="exceptionsFound">
            <div class="bg-orange-100 border border-orange-400 text-orange-700 px-4 py-3 rounded relative" role="alert">
              <strong class="font-bold">Exceptions found:</strong>
              <ul>
                <li v-for="participant in increaseExceptions" class="list-disc ml-8">
                  {{participant.name}} has a total percentage increase of {{participant.total_percentage_increase}}% which is greater than the max suggested of {{participant.max_recommendation}}%
                </li>
                <li v-for="participant in compaRatioExceptions" class="list-disc ml-8">
                  {{participant.name}} has a new compa ratio of {{Number(participant.new_compa_ratio).toFixed(2)}} which is greater than 1.20
                </li>

                <li v-for="participant in variablePayChangeExceptions" class="list-disc ml-8">
                  {{participant.name}} has a variable percentage pay change from {{Number(participant.current_variable_percentage).toFixed(1)}}% to {{Number(participant.new_variable_percentage).toFixed(1)}}%
                </li>
              </ul>
            </div>
          </div>

          <div class="my-4">
            Please make sure the above exceptions are documented in the comments and verified by you.
            If you have any questions, please reach out to your People Business Partner or the Total Rewards team by tagging them in the comments.
          </div>

          <div class="mt-8">
            <at-ta :members="mentionables">
              <textarea v-model="message" id="message" rows="4" class="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500" placeholder="Optional Comment"></textarea>
            </at-ta>
          </div>

        </section>

        <footer class="modal-footer">
          <p class="text-right text-sm">
            <button class="mr-4 bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded " @click="reject">
              <span v-if="message === ''">Send back worksheet</span>
              <span v-else>Send back worksheet with comment</span>
            </button>

            <button
                type="button"
                class="bg-white hover:bg-gray-100 text-gray-800 font-semibold py-2 px-4 border border-gray-400 rounded shadow"
                @click="close"
                aria-label="Close"
            >
              Close
            </button>
          </p>

        </footer>
      </div>
    </div>
  </transition>
</template>

<script>

import AtTa from "vue-at/dist/vue-at-textarea";

export default {
  name: 'RejectModal',
  components: {AtTa},
  props: {
    participants: {
      type: Object,
      required: true,
    },
    planner: {
      type: Object,
      required: true,
    },
    mentionables: {
      type: Array,
      required: true,
    }
  },
  data() {
    return {
      message: ''
    }
  },
  computed: {
    exceptionsFound() {
      if (this.increaseExceptions.length > 0 || this.compaRatioExceptions.length > 0 || this.variablePayChangeExceptions.length > 0) {
        return true
      }
      return false
    },

    increaseExceptions() {
      return this.participants.filter(participant => {
        return participant.total_percentage_increase > (participant.max_recommendation + 0.4)
      })
    },

    compaRatioExceptions() {
      return this.participants.filter(participant => {
        return participant.new_compa_ratio > 1.2
      })
    },

    variablePayChangeExceptions() {
      return this.participants.filter(participant => {
        return participant.total_percentage_increase &&
            participant.total_percentage_increase > 0 &&
            Number(participant.current_variable_percentage).toFixed(1) != Number(participant.new_variable_percentage).toFixed(1)
      })
    }
  },
  mounted() {
    this.$refs.modal.focus();
  },
  methods: {
    close() {
      this.$emit('close');
    },
    async reject() {
      if (this.message && this.message != '') {
        let data = {
          comment: this.message
        }

        await fetch(` /cycle_planner/${this.planner.id}/planner_comments`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'X-CSRF-Token': document.querySelector("meta[name=csrf-token]").content
          },
          body: JSON.stringify({planner_comment: data})
        })

        this.$emit('comment-added', this.message);
        this.message = null
      }

      this.$emit('reject');
    }
  },
};
</script>

<style scoped>
.modal-body {
  max-height: 70vh; /* Adjust the height as needed */
  overflow-y: auto;
}
</style>